
import { gsap } from "gsap";

const loaderTl = gsap.timeline({paused: true});
let loadOutCalled = false;

const hidePreloader = () => {
  if(loadOutCalled === false){
  
    console.log('hide');
    loaderTl.play();
    loadOutCalled = true;
  }
}
  
const checkForLoader = () => {
  
  loaderTl
  .to('.loader__img-cont', {duration: 0.4, autoAlpha: 0, y: -50, ease: "power3.inOut" })
  .to('.loader', {duration: 1, height: 0, ease: "power3.inOut" }, '-=0.2')
  .to('.loader', {duration: .1, display: 'none' });

  console.log(window.Webflow.require("lottie").lottie.getRegisteredAnimations().length);

  //See if there is a lottie file on the page
  if (window.Webflow.require("lottie").lottie.getRegisteredAnimations().length > 0){

    const dogIndex = window.Webflow.require("lottie").lottie.getRegisteredAnimations().findIndex(el => el.fileName.includes('dog-static'));

    if(dogIndex < 0){
      hidePreloader();
      console.log('hidePreloader1');
    }else{

      var loadLottie = setInterval(function(){

        let lotteObjs = window.Webflow.require("lottie").lottie.getRegisteredAnimations();
        let lotteObjNotLoaded = lotteObjs.filter(lotteObj => lotteObj.isLoaded !== true );

        if(lotteObjNotLoaded.length === 0) {
          console.log('hidePreloader2');
          hidePreloader();
          clearInterval(loadLottie);
        }
      }, 100);
    }
  }else{
    hidePreloader();
    console.log('hidePreloader3');
  }
}

export const loaderInit = () => {

  window.addEventListener("load", () => {
    console.log('load');
    checkForLoader();
  });

  setTimeout(function(){
    if(loadOutCalled != true){
      hidePreloader();
      console.log('hidePreloader Timelimit');
    }
  }, 10000);

  document.addEventListener('DOMContentLoaded', function () {
    console.log('DOMContentLoaded');
  }, false);
}