import { gsap } from "gsap";

var menuTl = gsap.timeline({paused: true});

menuTl
.fromTo('.nav_bg', {scale: 0}, {duration: 1, scale: 1, ease: "power3.inOut" })
.fromTo('.nav_logos.pink', 
{autoAlpha: 0}, 
{duration: 0.4, ease: "expo.out", autoAlpha: 1}, 0.1)

.fromTo('.nav_menu_trigger', 
{color: '#FFF'}, 
{duration: 0.2, ease: "expo.out", color: '#000000'}, 0.1)

.fromTo('.nav_menu_line', 
{backgroundColor: '#FFF'}, 
{duration: 0.2, ease: "expo.out", backgroundColor: '#000000'}, 0.1)


.fromTo('.nav_ctas .nav_button', 
{autoAlpha: 1}, 
{duration: 0.4, ease: "expo.out", autoAlpha: 0}, 0.1)

.fromTo('.menu_big .menu_title', 
{autoAlpha: 0, scale: 0.4}, 
{duration: 0.5, ease: "expo.out", autoAlpha: 1, scale: 1, stagger: 0.1}, 0.4)
.fromTo('.menu_small .menu_title, .menu_small .menu_small_point', 
{autoAlpha: 0, scale: 0.4}, 
{duration: 0.5, ease: "expo.out", autoAlpha: 1,  scale: 1, stagger: 0.1}, '-=0.2')
.fromTo('.header_social_links .social-item', 
{autoAlpha: 0, scale: 0.3}, 
{duration: 0.4, ease: "expo.out", autoAlpha: 1,  scale: 1, stagger: 0.1}, '-=0.6')
;


const menuTrigger = document.querySelector('.nav_menu_trigger');
const body = document.querySelector('body');

let menuIsOpen = false;
menuTrigger.addEventListener('click', function(){

    menuTl.play();

    if(menuIsOpen === true){
        menuTl.reverse();
    }

    menuIsOpen = !menuIsOpen;

    body.classList.toggle("menuIsOpen");
});