
import {gsap} from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

export const timeTickerAni = () => {

    const timeTicker = document.querySelector('.time_ticker');

    if(!timeTicker){
        return;
    }

    let tl = gsap.timeline({
        // yes, we can add it to an entire timeline!
        scrollTrigger: {
            trigger: ".time_ticker",
            once: false,
            pin: false,   // pin the trigger element while active
            start: "top bottom", // when the top of the trigger hits the top of the viewport
            end: "bottom top", // end after scrolling 500px beyond the start
            scrub: true, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        }
    });

    // add animations and labels to the timeline
    tl.addLabel("start")
    .to(".ticker_row:nth-child(2)", {scale: 1})
    .to(".ticker_rowing", {y: '-20%'})
    .to(".ticker_row:nth-child(3)", {scale: 1}, '-=0.5')
    .to(".ticker_row:nth-child(2)", {scale: .9}, '-=0.5')
    .to(".ticker_rowing", {y: '-40%'})
    .to(".ticker_row:nth-child(4)", {scale: 1}, '-=0.5')
    .to(".ticker_row:nth-child(3)", {scale: .9}, '-=0.5')
    .to(".ticker_rowing", {y: '-60%'})
    .to(".ticker_row:nth-child(5)", {scale: 1}, '-=0.5')
    .to(".ticker_row:nth-child(4)", {scale: .9}, '-=0.5');

}