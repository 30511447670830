
import {gsap} from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

export const homeWordsAni = () => {

    const introTitle = document.querySelector('.intro-title');

    if(!introTitle){
        return;
    }

    let tl = gsap.timeline({
        // yes, we can add it to an entire timeline!
        scrollTrigger: {
            trigger: ".intro-title",
            once: true,
            pin: false, 
            start: "top 60%",
            scrub: false,
        }
    });

    // add animations and labels to the timeline
    tl
    .fromTo('.intro-title .intro-title_word, .intro-title .intro-title_img, .intro_titels_parent .text-size-large, .intro_titels_parent .home-intro_cta-cont', 
        {autoAlpha: 0, scale: .4, y: 50}, 
        {duration: 0.6, ease: "expo.inOut", autoAlpha: 1, scale: 1, y: 0, stagger: 0.1});

}