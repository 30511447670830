
export const sliderServiceAni = () => {

    const intersectionElements = document.querySelectorAll('.service-slider_panel');

    if(!intersectionElements){
        return;
    }
        
    // Scroll ani
    const configIntersection = {
        root: null,
        rootMargin: '0px',
        threshold: [0.1, 0.5, 0.7, 1]
    };

    const sliderWrapper = document.querySelector('.service-slider_wrapper');

    const checkPointContainer = document.querySelector('.checkpoint-cointainer');
    const sliderServiceNavWrapper = document.querySelector('.service-slider-nav');
    const sliderServiceNav = document.querySelector('.service-slider-nav_inner');
    const checkPoints = Array();
    const sliderServiceNavItems = Array();


    if(sliderServiceNavWrapper){
        sliderServiceNavWrapper.classList.add('items-count-' + intersectionElements.length);
    }

    intersectionElements.forEach((el, index) => {

        const newCheckpoint = document.createElement("div");
        newCheckpoint.classList.add('checkpoint');
        newCheckpoint.setAttribute('id', `service-${index}`);

        if(!checkPointContainer){
            console.error('Checkpoint container not found');
            return;
        }
        checkPointContainer.appendChild(newCheckpoint);

        checkPoints.push(newCheckpoint);

        if(sliderServiceNav){
            const elTitle = el.querySelector('.service-slider_big-title').innerHTML;

            const newNavItem = document.createElement("a");
            newNavItem.classList.add('slider-nav_item');
            newNavItem.setAttribute('href', `#service-${index}`);
            newNavItem.classList.add(`slider-nav_item--${index}`);

            const labelContainer = document.createElement("div");
            labelContainer.classList.add('slider-nav_label');
            labelContainer.innerHTML = elTitle;

            newNavItem.appendChild(labelContainer);
            sliderServiceNav.appendChild(newNavItem);

            sliderServiceNavItems.push(newNavItem);


            const thumbDefault = intersectionElements[index].querySelector('.nav-img--default');
            const thumbCurrent = intersectionElements[index].querySelector('.nav-img--current');

            const lottieContainer = document.createElement("div");
            lottieContainer.classList.add('slider-nav_img');
            lottieContainer.appendChild(thumbDefault);
            lottieContainer.appendChild(thumbCurrent);

            sliderServiceNavItems[index].appendChild(lottieContainer);
        }
    });

    let lastIndex = 0;
    let currentIndex = 0;

    let observer = new IntersectionObserver(function(entries) {
    entries.forEach((entry) => {
        let element = entry.target;

        if(entry.isIntersecting === true){
        checkPoints.forEach((el, index) => {

            if(el === element){
                currentIndex = index;
            }
        });

        intersectionElements.forEach(el => {
            el.classList.remove('is-current');
        });

        intersectionElements[currentIndex].classList.add('is-current');
        sliderWrapper.setAttribute('data-current-slide', currentIndex);


        if(sliderServiceNav){

            // Si va in su o in giù?
            if(currentIndex > lastIndex){
                sliderServiceNavWrapper.classList.add('slide-down');
            }else{
                sliderServiceNavWrapper.classList.add('slide-up');
            }

            setTimeout(() => {
                sliderServiceNavWrapper.classList.remove('slide-down');
                sliderServiceNavWrapper.classList.remove('slide-up');
            }, 600);

            lastIndex = currentIndex;
            
            

            sliderServiceNavItems.forEach(el => {
                el.classList.remove('is-current');
                el.classList.remove('already-pass');
            });

            sliderServiceNav.setAttribute('data-current', currentIndex);

            sliderServiceNavItems[currentIndex].classList.add('is-current');

            const sliderServiceItemLenght = sliderServiceNavItems.length;
            const halfLenght = Math.floor(sliderServiceItemLenght / 2);

            const addAndRemoveAttr = ((isLast, itemIndex, dataIm) => {
                if(isLast && dataIm != 0){
                    sliderServiceNavItems[itemIndex].classList.add('is-last');
                    
                    setTimeout(() => {
                        sliderServiceNavItems[itemIndex].setAttribute('data-im', dataIm);
                        setTimeout(() => {
                            sliderServiceNavItems[itemIndex].classList.remove('is-last');
                        }, 500);
                    }, 500);
                }else{
                    sliderServiceNavItems[itemIndex].setAttribute('data-im', dataIm);
                }
            });


            for(let m = 0; m >= 0 - halfLenght; m--){

                let navItemIndex = currentIndex + m;

                if(!sliderServiceNavItems[navItemIndex]){
                    navItemIndex = sliderServiceItemLenght + (currentIndex + m);
                }
                
                if(sliderServiceNavItems[navItemIndex].classList.contains('already-pass')){ continue; }

                let elemPrevIm = parseInt(sliderServiceNavItems[navItemIndex].getAttribute('data-im'));

                let isLast = elemPrevIm > 0;

                sliderServiceNavItems[navItemIndex].classList.add('already-pass');
                addAndRemoveAttr(isLast, navItemIndex, m);
            }


            for(let i = 0; i <= halfLenght; i++){

                let navItemIndex = (currentIndex + i) < sliderServiceItemLenght ? (currentIndex + i) : (currentIndex + i) - sliderServiceItemLenght;

                if(sliderServiceNavItems[navItemIndex].classList.contains('already-pass')){ continue; }

                let elemPrevIm = parseInt(sliderServiceNavItems[navItemIndex].getAttribute('data-im'));
                
                let isLast = elemPrevIm === (0-halfLenght);

                sliderServiceNavItems[navItemIndex].classList.add('already-pass');
                addAndRemoveAttr(isLast, navItemIndex, i);
            }

        }
        }
    })
    }, configIntersection);

    checkPoints.forEach(intersectionElement => {
        observer.observe(intersectionElement);
    });
};